module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dino Link","short_name":"dino.link","start_url":"/","background_color":"#262626","theme_color":"#e50000","display":"fullscreen","icon":"content/heartring-perfecttrans.png","icon_options":{"purpose":"maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d1ad1df7726e26b60d9a7cb493502a8b"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1540,"linkImagesToOriginal":false,"showCaptions":true,"quality":80,"withWebp":true,"tracedSVG":true,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"htmlTitle":"Dino Link: CMS","htmlFavicon":"content/heartring-perfecttrans.png","modulePath":"/opt/build/repo/node_modules/@marscollective/gatsby-theme-link-free/src/cms","manualInit":true},
    }]
